main {
  min-height: 80vh;
  
}


h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  /* padding: 1rem 0; */
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 600px;
  padding: 30px;
  margin: 40px;
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
  
}

@media (max-width: 700px) {
  .carousel img {
    height:300px;
    width: 300px;
  }
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

#card{
  transition:2s;
}

#card:hover{
background-color:rgb(59, 55, 56);
transform: rotate(45deg);
}


#logo{
  height: 220px;
  width: 250px;
  /* border-radius: 100000000px; */
}
.bg-myred{
background-color:rgba(233, 232, 230, 0.932);

}

#bg-yellow{
  background-color:rgba(255, 215,0, 0.932);
  color:black;
  /* margin:20px; */
  }

  .fix-pad{
    padding:1rem;

  }

  #bg-red{
    background-color:rgba(218, 30, 30, 0.932);
    border-color: #ffe600;
    color:cornsilk;

    
    }

.bg-carousel{
  background-color:rgba(61, 4, 17, 0.932);
  
  }

.images{
  height: 640px;
  width: 640px;
  border-radius: 10000000px;
  
  background-color: rgba(57, 192, 237, 0.2);
}


.App {
  text-align: center;
}

.App .openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #d6627b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
form > div > label {
  margin: 1rem;
}
form > div > input,
form > div > textarea {
  padding: 0.5rem;
  min-width: 20rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}



Card.Body:hover{
  transform: translate(y);
  cursor:pointer;
  
}

CardGroup:hover{
  font-size: 100rem;
}



#images{ 
height: 520px;
width:100%;
border-radius: 20px;
} 

.dropdown-item{
  color:black;
}

figcaption{
  position: absolute;
  bottom:0rem;
  margin:5% 5% 0% 0%;
  font-size: 1.0em;
  /* padding: 0.5rem; */
}

.parent-image-grid{
display: flex;
}

.image-items{
  margin:10px;


}


/* Modal css */
/* .modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
} */